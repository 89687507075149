import React, {useEffect, useState, PureComponent} from 'react'
import { MDBTable, MDBTableBody, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import { TraeNombre } from '../hooks/Funciones'
import { NumericFormat } from 'react-number-format'

import { BarChart, Bar, Rectangle, XAxis,Line, LineChart,  YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const VerEscritorio = ({ totalDonaciones, totalGastos, mediosFormat, UrlMediosdepago, estadisticas, categorias, selectedYear, handleYearChange, currentYear, totalYear  }) => {


	const years = Array.from({ length: 5 }, (_, i) => currentYear - i); // Últimos 10 años
	console.log('estadisticas', estadisticas)

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-CO', {
		  style: 'currency',
		  currency: 'COP',
		  minimumFractionDigits: 0, // Mostrar sin decimales
		}).format(value);
	  };

	  const groupByMonth = (data) => {
		return data.reduce((acc, item) => {
		  const { mes } = item;
		  if (!acc[mes]) {
			acc[mes] = [];
		  }
		  acc[mes].push(item);
		  return acc;
		}, {});
	  };

	  const groupedData = groupByMonth(categorias);


	  const calculaporcentaje = (mes, valor) => {

		let result = 0
		let total = 0
		estadisticas.map(item =>{
			if (item.mes === mes){
				total = item.total_gastos
			}
		})

		if (valor > 0){
			result = parseFloat(((valor / total) * 100).toFixed(1)) 
		}else result = '0'

		return result


	  }


	return (
		<div>
			<div style={{display: 'flex', flex: 1, marginTop: 20}}>

					<div style={{flex: 1, backgroundColor: '#ddf1da', padding: 10}}>
							<span>Saldo Total</span><br/>
							<span style={{fontSize: 25, fontWeight: 400}}>
								<NumericFormat displayType="text" value={parseInt(totalDonaciones.resultDona) - parseInt(totalGastos)} prefix={'$'} thousandSeparator={true} />
							</span>
					</div>

					<div style={{flex: 1, backgroundColor: '#93c1e8', padding: 10}}>
							<span>Total Ingresos</span><br/>
							<span style={{fontSize: 25, fontWeight: 400}}>
								<NumericFormat displayType="text" value={parseInt(totalDonaciones.resultDona)} prefix={'$'} thousandSeparator={true} />
							</span>
					</div>

					<div style={{flex: 1, backgroundColor: '#ffe3d4', padding: 10}}>
							<span>Total Gastos</span><br/>
							<span style={{fontSize: 25, fontWeight: 400}}>
								<NumericFormat displayType="text" value={parseInt(totalGastos)} prefix={'$'} thousandSeparator={true} />
							</span>
					</div>

					<div style={{flex: 1, backgroundColor: '#fff4ca', padding: 10, marginLeft: 10}}>
							<span>Total Diezmos</span><br/>
							<span style={{fontSize: 25, fontWeight: 400}}>
								<NumericFormat displayType="text" value={parseInt(totalDonaciones.totaldiez)} prefix={'$'} thousandSeparator={true} />
							</span>
					</div>

					<div style={{flex: 1, backgroundColor: '#fff4ca', padding: 10}}>
							<span>Total Ofrendas</span><br/>
							<span style={{fontSize: 25, fontWeight: 400}}>
								<NumericFormat displayType="text" value={parseInt(totalDonaciones.totalofren)} prefix={'$'} thousandSeparator={true} />
							</span>
					</div>
			</div>
			<div className='row' style={{padding: 15}}>

					{mediosFormat.map((item, index) => { 
							return(
								<div className='col-md-4' style={{backgroundColor: item.color, padding: 10, color: 'white'}} key={index}>
									{item.imagen !== '' ?
										<div className="ImgProducto"><img src={`${UrlMediosdepago}${item.imagen}`} /></div>
										:
										<div className="ImgProductoSin"></div>
									}
									<span>{item.nombre}</span><br/>
									<span style={{fontSize: 25, fontWeight: 400}}>
										<NumericFormat displayType="text" value={item.total !== null ? parseInt(item.total) : 0} prefix={'$'} thousandSeparator={true} />
									</span>
								</div>
							)	
					})}
			</div>

			<div className='row' style={{display: 'flex', flex: 1, paddingTop: 20, paddingLeft: 20, paddingBottom: 0}}>
				{/* <h4>Estadísticas mensuales 2024:</h4><br></br> */}
				<div style={{}}>
				<h4>Estadísticas mensuales del año: 
					<select
						id="year-select"
						value={selectedYear}
						onChange={handleYearChange}
						style={{
						padding: "5px 10px",
						borderRadius: "4px",
						border: "1px solid #ccc",
						marginLeft: 10,
						fontSize: 18,
						}}
					>
						{years.map((year) => (
						<option key={year} value={year}>
							{year}
						</option>
						))}
					</select>

				</h4>
					</div>
				<ResponsiveContainer width="100%" height={400}>
					<BarChart
					width={500}
					height={300}
					data={estadisticas}
					margin={{
						top: 20,
						right: 30,
						left: 40,
						bottom: 10,
					}}
					>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="mes" />
					<YAxis tickFormatter={formatCurrency}/>
					<Tooltip formatter={formatCurrency}/>
					<Legend />
					<Bar dataKey="total_donaciones" fill="#00b894" activeBar={<Rectangle fill="#00b894" stroke="#636e72" />} />
					<Bar dataKey="total_gastos" fill="#ff7675" activeBar={<Rectangle fill="#ff7675" stroke="#636e72" />} />
					</BarChart>
				</ResponsiveContainer>
			</div>



			<div className='row' style={{padding: 15}}>
					{estadisticas.map((item, index) => { 
							return(
								<div className='col-md-1' style={{backgroundColor: index % 2 !== 0 ? '#e4e4e4' : '#f1f1f1', padding: 10, color: '#333'}} key={index}>
									<span><b>{item.mes}</b></span><br/>
									<span style={{fontSize: 15, fontWeight: 400, color: '#00b894'}}>
										<NumericFormat displayType="text" value={item.total_donaciones} prefix={'$'} thousandSeparator={true} />
									</span><br></br>
									<span style={{fontSize: 15, fontWeight: 400, color: '#ff7675', borderBottom: "1px solid #ccc"}}>
										<NumericFormat displayType="text" value={item.total_gastos} prefix={'$'} thousandSeparator={true} />
									</span>
									<br></br>
									
									{item.total_donaciones - item.total_gastos > 0 ? 
										<span style={{fontSize: 15, fontWeight: 400, color: '#00b894'}}>
											<NumericFormat displayType="text" value={item.total_donaciones - item.total_gastos} prefix={'$'} thousandSeparator={true} />
										</span>
										:
										<span style={{fontSize: 15, fontWeight: 400, color: '#ff7675'}}>
											<NumericFormat displayType="text" value={item.total_donaciones - item.total_gastos} prefix={'$'} thousandSeparator={true} />
										</span>
									}
										
									
								</div>
							)	
					})}
			</div>

			<div className='row' style={{padding: 15}}>
					
					<div className='col-md-4' style={{backgroundColor: '#06934c', padding: 10, color: 'white'}}>
						<span>Total Ingresos:</span><br/>
						<span style={{fontSize: 25, fontWeight: 400}}>
							<NumericFormat displayType="text" value={totalYear.ingresos} prefix={'$'} thousandSeparator={true} />
						</span>
					</div>
					<div className='col-md-4' style={{backgroundColor: '#e23e3d', padding: 10, color: 'white'}}>
						<span>Total Gastos:</span><br/>
						<span style={{fontSize: 25, fontWeight: 400}}>
							<NumericFormat displayType="text" value={totalYear.gastos} prefix={'$'} thousandSeparator={true} />
						</span>
					</div>
					<div className='col-md-4' style={{backgroundColor: '#f69d0e', padding: 10, color: 'white'}}>
						<span>Saldo:</span><br/>
						<span style={{fontSize: 25, fontWeight: 400}}>
							<NumericFormat displayType="text" value={totalYear.ingresos - totalYear.gastos} prefix={'$'} thousandSeparator={true} />
						</span>
					</div>
			</div>



			<div style={{paddingTop: 20, paddingLeft: 20, paddingBottom: 40}}>
				<h4>Categorias {selectedYear}:</h4><br></br>
						<div className="row">
							{Object.keys(groupedData).map((mes) => (
							<div className="col-md-4 mb-4" key={mes}>
								<div className="">
								<div className="card-header">
									<h5>{mes}</h5>
								</div>
								<div className="card-body" style={{padding: 5}}>
									{groupedData[mes].map((item, index) => (
									<div key={index} className="d-flex justify-content-between">
										<span className='estados' style={{backgroundColor: item.color, marginTop: 3, padding: 3}}>
											{item.categoria}
											<span> ({calculaporcentaje(mes,item.total_gasto )}%)</span>
										</span>
										
										<span>
											<NumericFormat displayType="text" value={item.total_gasto} prefix={'$'} thousandSeparator={true} />
										</span>
									</div>
									))}
								</div>
								</div>
							</div>
							))}
						</div>
					</div>

				<div>
			</div>

			
		</div>
	)
}

export default VerEscritorio